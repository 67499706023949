import React from 'react'

import { Link } from 'react-router-dom';
import "../styles/Home.css";
import afcons from "../assets/clients/afcons.png";
import apco from "../assets/clients/apco.png";
import ashoka from "../assets/clients/ashoka.png";
import bauer from "../assets/clients/bauer.png";
import cds from "../assets/clients/cds.png";
import dilip from "../assets/clients/dilip.png";
import dra from "../assets/clients/dra.png";
import dthakkar from "../assets/clients/dthakkar.jpg";
import heritage from "../assets/clients/heritage.png";
import hgiel from "../assets/clients/hgiel.png";
import itcem from "../assets/clients/itcem.jpg";
import jkumar from "../assets/clients/jkumar.jpg";
import kec from "../assets/clients/kec.png";
import keller from "../assets/clients/keller.png";
import larsentoubro from "../assets/clients/larsentoubro.png";
import mkc from "../assets/clients/mkc.png";
import montecarlo from "../assets/clients/montecarlo.png";
import ncc from "../assets/clients/ncc.png";
import patel from "../assets/clients/patel.png";
import pinaccle from "../assets/clients/pinaccle.jpg";
import shapoorji from "../assets/clients/shapoorji.png";
import shivalaya from "../assets/clients/shivalaya.png";
import singla from "../assets/clients/singla.png";
import tata from "../assets/clients/tata.png";
import indiamap_3d from "../assets/indiamap_3d.jpg";
import heroimage_1 from "../assets/heroimage_1.jpg";
import heroimage_2 from "../assets/heroimage_2.jpg";
import heroimage_3 from "../assets/heroimage_3.jpg";
import vision from "../assets/vision.jpg";
import values from "../assets/values.png";
import { motion } from "framer-motion"


import Slider from "react-slick";
import { useState } from 'react';


import bentonite from "../assets/products/bentonite.png";
import drillingpolymer from "../assets/products/drillingpolymer.png";
import hydratedlime from "../assets/products/hydratedlime.png";
import microsilica from "../assets/products/microsilica.png";
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';


import chennai from "../assets/projects/chennai.png";
import dmrcl from "../assets/projects/dmrcl.png";
import jaipur from "../assets/projects/jaipur.png";
import kolkata from "../assets/projects/kolkata.jpg";
import metro from "../assets/projects/metro.png";
import nagpur from "../assets/projects/nagpur.png";
import ncrtc from "../assets/projects/ncrtc.png";
import nhai from "../assets/projects/nhai.jpg";
import nhsrcl from "../assets/projects/nhsrcl.png";
import noida from "../assets/projects/noida.png";
import patna from "../assets/projects/patna.jpg";
import upmetro from "../assets/projects/upmetro.png";


const Home = () => {
  const settings_clientspage = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2400,
    autoplaySpeed: 2400,
    cssEase: "linear",
    slidesPerRow: 2,
    rows: 2,
    pauseOnHover: false
  };

  const settings_homepage = {
    autoplay: true,
    speed: 2400,
    autoplaySpeed: 1000,
    infinite: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings_approvedbypage = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: false,
    rtl : true
  };





  const [Email, setEmail] = useState("");

  const [Name, setName] = useState("");

  const HandleChange = (e) => {
    if (e.target.name === "Email") {
      setEmail(e.target.value);
    }

    if (e.target.name === "Name") {
      setName(e.target.value);
    }
  }




  const sendEmail = (e) => {
    e.preventDefault();

    const templateparams = {
      from_name: Name,
      from_email: Email,
      to_name: 'WallGrip India Pvt. Ltd',

    }

    emailjs.send('service_0ccz7uf', 'template_z82buyx', templateparams, 'Do1hGeyOkzo9UukLq')
      .then((response) => {
        
        alert('Email sent successfully');
        setName('');
        setEmail('');
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <>

      <div className="homepage" id='homepage' >
        <Helmet>
          <title>WallGripIndia-Home</title>
        </Helmet>
        <div className="slider-text" >
          <motion.div initial={{ opacity: 0, y: 40 }}
            animate={{ color: 'white', opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}  >
            <h1>Quality product for a new age INDIA</h1>
            <p>WallGrip products are ISO 9001 standardised, assuring a better build quality. We are paving the way for stronger and better infrastructure projects around the country.</p>
          </motion.div>
        </div>
        <Slider {...settings_homepage}>
          <div>
            <div className="slider-item">
              <img src={heroimage_1} alt="not found" />

            </div>

          </div>
          <div>
            <div className="slider-item">
              <img src={heroimage_2} alt="not found" />

            </div>
          </div>
          <div>
            <div className="slider-item">
              <img src={heroimage_3} alt="not found" />

            </div>
          </div>

        </Slider>
      </div>

      <div className="visionpage">
        <div className="values">
          <div>
            <img src={values} alt="not found" />
            <h1>Our Values</h1>
          </div>
          <p>Promises are made to be fulfilled, our word is our bond. Relationships are nurtured to maintain the highest ethical and moral standards around. Respect for people is measured by how we approach and interact with one another, contribute to the diversity flow, have productive interactions, and do a good job.</p>
        </div>
        <div className="visions">
          <div>
            <img src={vision} alt="not found" />
            <h1>Our Vision</h1>
          </div>
          <p>To be the most respectful and successful chemical trading company in India.</p>

        </div>
      </div>


      <div className="productspage" id='productspage' >
        <div className="product_heading">
          WallGrip Products
        </div>
        <div className="product_section">
          <div className="productitem">
            <a href="/drillingpolymer">
              <img src={drillingpolymer} alt="Drilling Polymer" />
            </a>
          </div>

          <div className="productitem">
            <a href="/bentonite">
              <img src={bentonite} alt="Bentonite" />
            </a>
          </div>

          <div className="productitem">
            <a href="/microsilica">
              <img src={microsilica} alt="Microsilica" />
            </a>
          </div>

          <div className="productitem">
            <a href="/hydratedlime">
              <img src={hydratedlime} alt="Hydrated Lime" />
            </a>
          </div>

        </div>

      </div>



      <div className="projectspage" id='projectspage' >
        <div className="projectpage_left">
          <img src={indiamap_3d} alt="india map" />
        </div>
        <div className="projectpage_right">
          <h1>WallGrip is trusted by drilling contracters around INDIA</h1>
          <p>Our products and services are as diverse as INDIA itself. There are no barriers when it comes to languages, soil types, and geotechnical challenges.</p>
          <div className="projectbtn">
            <Link to={'/projects'} ><button>Our Projects</button></Link>
          </div>
        </div>
      </div>


      <div className="clientspage" id='clientspage' >
        <div className="clientspage_heading">
          Our Clients
        </div>
        <div className="clientspage_carousel">
          <Slider {...settings_clientspage}>
            <div>
              <img src={afcons} alt="not found" />
            </div>
            <div>
              <img src={apco} alt="not found" />
            </div>
            <div>
              <img src={ashoka} alt="not found" />
            </div>
            <div>
              <img src={bauer} alt="not found" />
            </div>
            <div>
              <img src={cds} alt="not found" />
            </div>
            <div>
              <img src={dilip} alt="not found" />
            </div>
            <div>
              <img src={dra} alt="not found" />
            </div>
            <div>
              <img src={dthakkar} alt="not found" />
            </div>
            <div>
              <img src={heritage} alt="not found" />
            </div>
            <div>
              <img src={hgiel} alt="not found" />
            </div>
            <div>
              <img src={itcem} alt="not found" />
            </div>
            <div>
              <img src={jkumar} alt="not found" />
            </div>
            <div>
              <img src={kec} alt="not found" />
            </div>
            <div>
              <img src={keller} alt="not found" />
            </div>
            <div>
              <img src={larsentoubro} alt="not found" />
            </div>
            <div>
              <img src={mkc} alt="not found" />
            </div>
            <div>
              <img src={montecarlo} alt="not found" />
            </div>
            <div>
              <img src={ncc} alt="not found" />
            </div>
            <div>
              <img src={patel} alt="not found" />
            </div>
            <div>
              <img src={pinaccle} alt="not found" />
            </div>
            <div>
              <img src={shapoorji} alt="not found" />
            </div>
            <div>
              <img src={singla} alt="not found" />
            </div>
            <div>
              <img src={shivalaya} alt="not found" />
            </div>
            <div>
              <img src={tata} alt="not found" />
            </div>

          </Slider>
        </div>
      </div>


      <div className="approvedpage" >
        <div className="clientspage_heading">
          Approved By
        </div>
        <div className="clientspage_carousel">
          <Slider {...settings_approvedbypage}>
            <div>
              <img src={chennai} alt="not found" />
            </div>
            <div>
              <img src={dmrcl} alt="not found" />
            </div>
            <div>
              <img src={jaipur} alt="not found" />
            </div>
            <div>
              <img src={kolkata} alt="not found" />
            </div>
            <div>
              <img src={metro} alt="not found" />
            </div>
            <div>
              <img src={nagpur} alt="not found" />
            </div>
            <div>
              <img src={ncrtc} alt="not found" />
            </div>
            <div>
              <img src={nhai} alt="not found" />
            </div>
            <div>
              <img src={nhsrcl} alt="not found" />
            </div>
            <div>
              <img src={noida} alt="not found" />
            </div>
            <div>
              <img src={patna} alt="not found" />
            </div>
            <div>
              <img src={upmetro} alt="not found" />
            </div>


          </Slider>
        </div>
      </div>



      <div className="contact" id="contact">
        <div className="contactform">
          <a href="/#homepage" id='brochure_btn' >Download brochure</a>
          <h1>Get In Touch</h1>
          <form onSubmit={sendEmail} >
            <input type='text' name='Name' placeholder='Enter Your Name' required value={Name} onChange={HandleChange} />
            <input type='email' name='Email' placeholder='Enter your Email' required value={Email} onChange={HandleChange} />



            <button type='submit' value="Send">Connect</button>

          </form>
        </div>
      </div>

    </>
  )
}

export default Home