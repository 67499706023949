import { Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Bentonite from "./components/Bentonite";
import Hydratedlime from "./components/Hydratedlime";
import Drillingpolymer from "./components/Drillingpolymer";
import Microsilica from "./components/Microsilica";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route exact path="/about" element={<About/>}></Route>
        <Route exact path="/projects" element={<Projects/>}></Route>
        <Route exact path="/bentonite" element={<Bentonite/>}></Route>
        <Route exact path="/hydratedlime" element={<Hydratedlime/>}></Route>
        <Route exact path="/drillingpolymer" element={<Drillingpolymer/>}></Route>
        <Route exact path="/microsilica" element={<Microsilica/>}></Route>
      </Routes>

      <Footer />
    </>
  );
}

export default App;
