import React from 'react';
import product_section from "../assets/product_section.jpg";
import microsilicatech from "../assets/microsilicatech.png";
import "../styles/microsilica.css";
import Productnav from './Productnav';
import { Helmet } from 'react-helmet';

const Microsilica = () => {
  return (
    <div className="microsilica">
      <Helmet>
          <title>WallGripIndia-MicroSilica</title>
        </Helmet>
      <div className="microsilica_head">
        <img src={product_section} alt="about_background_image" />
        <div className="about_head_text">WallGrip Products</div>
      </div>

      <Productnav/>

      <div className="micro-1">
        <h2>What is Micro Silica?</h2>
        <p>Micro Silica, also known as silica fume is an ultra-fine powder collected as a by-product of the silicon and ferrosilicon alloy production and consists of spherical particles, amorphous glassy spheres of silicon dioxide (SiO2). The main field of application is as pozzolanic material for high performance concrete. </p>

        <h2>Production and Use</h2>
        <p>Silica fume is a byproduct in the carbothermic reduction of high-purity quartz with the carbonaceous materials like coal, coke, wood-chips, in electric arc furnaces in the production of silicon and ferrosilicon Alloys. The Micro Silica is formed when SiO Gas, given off as the quartz reduces, mixes with oxygen in the upper parts of the furnace. Here the SiO is oxidised to SiO2, condensing into the spherical particles of Micro-Silica that form the major part of the smoke or fume from the furnace. Hence, the alternative names for the material “Condensed Silica Fume” or “Silica Fume”. Micro Silica will react with the calcium hydroxide from the cement, which will form more of the calcium silicate hydrate, increasing the strength of the concrete.</p>

        <h2>Technical Data</h2>
        <img src={microsilicatech} alt="" />

        <h2>Quality Control</h2>
        <p>The chemical composition and physical properties are regularly tested in accordance  with international standards. WallGrip Materials is certified according to ISO 9001. </p>

        <h2>Conformance to Standards</h2>
        <p>WallGrip Micro Silica is comparatively same to certain standards from:</p>
        <ul>
          <li>American Society of Testing and Method</li>
          <li>European Committee of Standardisation</li>
        </ul>

        <h2>Packaging</h2>
        <p>The products are supplied in a range of packaging:</p>
        <ul>
          <li>25 or 50 kg bags</li>
          <li>Jumbo bags in a variety of designs and sizes depending on product and production plant.</li>
        </ul>

        <h2>Storage</h2>
        <p>WallGrip Micro Silica should be stored at room temperature ( Min 10°C and Max 35°C). Kept dry and out of direct sunlight. If these conditions are maintained and the product packaging is unopened, then a shell life of 1 year can be expected.</p>

        <h2>Health & Safety Information</h2>
        <p>WallGrip Micro Silica should only be used as directed. We always recommend that the safety data sheet is carefully read prior to the application of the material. Our recommendations for professional equipment should be  strictly adhered to for your personal protection. The safety data sheet is available upon request from your local WallGrip Representative.</p>

      </div>
    </div>
  )
}

export default Microsilica