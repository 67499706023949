import React from "react";
import about_background from "../assets/about_background.jpg";
import "../styles/About.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="about_page">
      <Helmet>
        <title>WallGripIndia-AboutUs</title>
      </Helmet>
      <div className="about_head">
        <img src={about_background} alt="about_background_image" />
        <div className="about_head_text">About Us</div>
      </div>
      <div className="about_content">
        <div className="about_content_top">
          <p><span>WallGrip India</span> Pvt. Ltd. is ISO 9001-2015 certified leading supplier of drilling fluids and specialty
            chemical. We bring legacy of over two decade of business support and service.</p>
          <p>WIPL offer a wide range of water soluble polymers for all types of infrastructure and
            construction industry . We have developed products with help of advance R&D for product to
            be used in sand, ,soil, gravel, and silt. Our polymers are designed to improve the rheology,
            filtration properties, and lubricity of drilling fluids, which helps to reduce the cost of drilling and
            improve the safety of workers. </p>
          <p>We are committed to providing our customers with the best possible products and services.
            Our team of experienced engineers and scientists work closely with our customers to develop
            custom solutions that meet their specific needs. We are also committed to environmental
            stewardship and use sustainable practices in the production of our products. </p>
        </div>


        <div className="about_content_mid">
          <h2>
            WallGrip polymers are used in a variety of drilling applications, including:
          </h2>
          <div className="about_content_boxes">
            <div className="blue">
              Foundationn Drilling
            </div>
            <div className="orange">
              Geotechnical Drilling
            </div>
            <div className="blue">
              Water well drilling
            </div>
            <div className="orange">
              Tunnelling
            </div>
            <div className="blue">
              Mining
            </div>
            <div className="orange">
              Oil and gas drilling
            </div>
          </div>
        </div>


        <div className="about_content_bottom">
          We serve customers pan India and are committed to providing them with the best
          possible products and services. Wallgrip India Pvt. Ltd. is a company dedicated to
          providing high-quality wall solutions. Established with a commitment to innovation
          and excellence, we have been serving our customers with premium wall covering
          products and services.
        </div>
      </div>
    </div>
  );
};

export default About;
