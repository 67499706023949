import React from 'react'
import "../styles/hydratedlime.css";
import product_section from "../assets/product_section.jpg";
import Productnav from './Productnav';
import { Helmet } from 'react-helmet';
import hydrated_lime_attr from "../assets/hydrated_lime_attr.png";
import hydrated_lime_making from "../assets/hydrated_lime_making.png"

const Hydratedlime = () => {
  return (
    <div className="hydratedlime">
      <Helmet>
          <title>WallGripIndia-HydratedLime</title>
        </Helmet>
      <div className="hydratedlime_head">
        <img src={product_section} alt="about_background_image" />
        <div className="about_head_text">WallGrip Products</div>
      </div>

      <Productnav/>

      <div className="hydrated-1">
        <h1>What is Hyrdrated Lime?</h1>
        <p>Hydrated Lime is also known as calcium hydroxide, Hydrated Lime with chemical formula of CaOH2. It is formed by Processing of Quick lime with water and removing extra contents from Quick Lime to give lime its purest form. Ca(OH)2 is dry fine without any grits and impurities. </p>
        <p>Premium Hydrated Lime: Premium hydrated Lime manufactured at Manoj Kumar Kharakia Co. is the purest form of Lime in India. It contains highest calcium content which provides Highest Productivity.</p>
        <p>Our Mfg Unit is at Joshi Farm Hariyadhana Road, P.O Borunda-342604 Dist Jodhpur (RAJ.)</p>
        <p>We are leading manufacturer, supplier and trader of slaked lime 80-85% to our respected clients.it is available in a colourless crystal or white powder. These are formulated using top quality chemicals and procured from calcium oxide. These chemicals are also checked stringently by quality analysts to ensure that defect free and best chemicals to our honoured clients. Our range of chemicals are available at economical prices in the market.
Perfect & Prime Hydrated Lime: perfect & prime hydrated Lime has a specific customer base with its own advantages of comparatively low cost. These are standard product and we are completely armed with our infrastructure to cater any demand.</p>

        <div className="hydrated-1-1">
          <div className="hydrated-1-1-left">
            <h2>Manufacturing Process</h2>
            <ul>
              <li>It is a colorless crystal or white powder, and is
obtained when calcium oxide is mixed, or “slaked”
with water.</li>
              <li>It can also be precipitated by mixing an aqueoussolution of calcium chloride and an aqueoussolution of sodium hydroxide.</li>
              <li>CaO + H<sub>2</sub>O → Ca(OH)<sub>2</sub></li>
            </ul>
          </div>

          <div className="hydrated-1-1-right">
            <img src={hydrated_lime_making} alt="" />
          </div>
        </div>

        <div className="hydrated-1-2">
          <div className="hydrated-1-2-left">
            <img src={hydrated_lime_attr} alt="" />
          </div>
          <div className="hydrated-1-2-right">
            <h3>Attributes</h3>
            <ul>
              <li>Pure</li>
              <li>Natural</li>
              <li>Best chemical reagent </li>
              <li>Reacts violently to acid</li>
              <li>Turns milky when carbon dioxide is passed </li>
              <li>Colorless crystal</li>
              <li>Dry</li>
            </ul>
          </div>
        </div>

        <h2>Uses of Hydrated Lime Powder</h2>

        <div className="hydrated-1-3">
          <ul>
            <li>It is used in sugar refining, paper processing and in the manufacture of Di Calcium Phosphate. </li>
            <li>It is used in leather and flue gas treatment.</li>
            <li>It is used in paint, masonry and agricultural applications</li>
            <li>It is used in soil stabilization, water treatment and pharmaceutical applications.</li>
            <li>It is used in Effluent Treatment plant.</li>
            <li>It is used to control the pH value</li>
            <li>It is used in the food, leather and paper industries</li>
            <li>It is used as pesticides and fertilizers.</li>
            <li>It is used in metallurgical industry in the making ofCopper,
Aluminum, Steel, Iron, etc.</li>
            <li>It is used in marble/granite cutting and in the glassindustry.</li>
            <li>It is used in the manufacturing of bleaching powder and
rubber industry.</li>
            <li>It is used in the construction of roads</li>
          </ul>
        </div>
      </div>



    </div>
  )
}

export default Hydratedlime