import React, { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import "../styles/Header.css";
import wallGriplogo from "../assets/wallGriplogo.png";

const Header = () => {
    var [MenuClass, setMenuClass] = useState("navbar");



    const MenuControl = () => {

        if (MenuClass === "navbar") {
            setMenuClass("navbar responsive");
        }
        else {
            setMenuClass("navbar");
        }


    };

    return (
        <div className={MenuClass}>
            <img src={wallGriplogo} alt='' />
            <a href="/#contact">Contact</a>

            <a href="/#clientspage">Our Clients</a>


            <a href="/drillingpolymer">Our Products</a>


            

            <a href="/#projectspage">Projects</a>

            <a href="/about">About</a>

            <a href="/#homepage">Home</a>

            <a href="javascript:void(0);" className="icon" onClick={() => MenuControl()}>
                <GiHamburgerMenu />
            </a>

        </div>
    )
}

export default Header