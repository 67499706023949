import React from 'react'
import "../styles/bentonite.css";
import product_sectiion from "../assets/product_section.jpg";
import Productnav from './Productnav';
import { Helmet } from 'react-helmet';
import bentonite_setup from "../assets/bentonite_setup.png";
import bentonite_mixing from "../assets/bentonite_mixing.png";

const Bentonite = () => {
  return (
    <div className="Bentonite">
      <Helmet>
          <title>WallGripIndia-Bentonite</title>
        </Helmet>
      <div className="bentonite_head">
        <img src={product_sectiion} alt="about_background_image" />
        <div className="about_head_text">WallGrip Products</div>
      </div>

      <Productnav />

      <div className="bentonite_content">
      <div className="bentonite-1">
        <h2>What is Bentonite ?</h2>
        <p>Bentonite is a very fine-grained clayey material having a high percentage of montmorillonite material. Bentonite clay is a mined
          product. Usually, two types are there – Sodium based and Calcium based. Sodium based is suitable for soil stabilization work.
          Bentonite slurry shall be used for stabilizing the sides of the pile borehole. Bentonite powder shall be mixed with water to make
          slurry having specified parameters, conforming to IS:6186 and procured from the established source shall be used.</p>
      </div>
      <div className="bentonite-2">
        <div className="bentonite-2-1">
          <h3>Collapse of Soil:</h3>
          <p>Stabilization of soil is essential for the quality of the pile as well as for time and cost economy. Stabilization of soil depends on soil characteristics mainly on cohesive factor and angle of friction. There are different types of collapse observed during piling operation, which are as under.</p>
        </div>
        <div className="bentonite-2-1">
          <h3>During pile boring:</h3>
          <p>During pile boring operation, due to the collapse of the inside diameter of the borehole increases irregularly. Actual pouring of
concrete in pile bore will increase to fill the over breakage volume.</p>
        </div>
        <div className="bentonite-2-1">
          <h3>Before pouring of concrete:</h3>
          <p>After flushing of the borehole bottom and before pouring of concrete into the pile bore, if side soils of the borehole collapse, again loose muck will be deposited at the pile bottom which affects the quality and end bearing capacity of pile adversely.</p>
        </div>
        <div className="bentonite-2-1">
          <h3>During pile concrete:</h3>
          <p>During concreting there is a chance of producing some soil pockets inside the build-up concrete, which may cause damage to the
pile shaft and decrease the load bearing capacity of the pile.</p>
        </div>
      </div>

      <div className="bentonite-3">
        <h2>Factor affecting collapse:</h2>
        <ul>
          <li>Cohesiveness and angle of friction.</li>
          <li>Density of soil.</li>
          <li>Pour water pressure.</li>
          <li>Vibration.</li>
          <li>Borehole depth.</li>
          <li>Fluid inside the pile bore.</li>
        </ul>
      </div>

      <div className="bentonite-4">
        <div className="bentonite-4-1">
        <div className="bentonite-4-1-left">
          <h3>Soil Stabilization using Bentonite slurry:</h3>
          <p>The technology of soil stabilization works only for sodium-based bentonite clay. It is montmorillonite clay mineral which swells considerably, while comes in contact with water. Suspension contains exchangeable sodium cations(Na+). These cations(Na+) WallGrip Bentonite for Piling together with the cations generated in water exchanged by negatively charged ions, accumulated on soil particles of the vertical cut surface of pile borehole, will form electric double layer resulting stability of the vertical surface of the soil.
The thixotropic property of bentonite suspension maintains the consistency of the slurry by virtue of electrical bonds, created due to ionization. During turbulence/disturbance in the suspension, jell materials become fluid by breaking the electrical bonds or otherwise during undisturbed condition the suspension will form a jelly.</p>
        </div>

        <div className="bentonite-4-1-right">
          <h3>Bentonite slurry Property and Tests:</h3>
          <p>The powder mixed with water takes normally 12 hours soaking time to get the maturity. Soaked bentonite shall work like jell
material. For stabilization of vertical cut soil surface during boring of piles is based on consideration of the following properties:
Laboratory Test:</p>
          <ul>
            <li>Swelling Index: 100 % or more in 12 hours.</li>
            <li>Liquid limit: More than 300% and less than 450%</li>
            <li>Field Test:
              <ul>
                <li>Marsh viscosity: Between 30-40 seconds</li>
                <li>The pH value: Between 9 to 12</li>
                <li>The density of slurry: 1.05-1.12gm /cc</li>
              </ul>
            </li>
          </ul>
        </div>
        </div>

        <div className="bentonite-4-2">
          <div className='bentonite-4-2-left' >
            <img src={bentonite_setup} alt="" />
          </div >
          <div className="bentonite-4-2-right">
            <h3>Bentonite Setup Installation:</h3>
            <p>Setup Installation for piling includes the following arrangement which will be arranged/constructed as per site requirement.</p>
            <ul>
              <li>Water tank</li>
              <li>Mixing tank</li>
              <li>Swelling, curing and storing tank</li>
              <li>Pumping and recycling arrangement</li>
              <li>Desander</li>
              <li>Return tank</li>
            </ul>
          </div>
        </div>

        <div className="bentonite-4-3">
          <div className="bentonite-4-3-left">
            <h3>Bentonite mixing:</h3>
            <p>Bentonite will be mixed with the help of agitator in a central mixing tank. Freshly mixed bentonite slurry transferred to the
swelling tank and will be allowed for swelling minimum of 12 hrs. Once the mixed slurry is in the swelling tank, it will be further
re-mixed with the help of a pump, by recycling the slurry in-to the same swelling tank. Then properly soaked and mixed slurry will
be transferred to the storing tank from which it shall be pumped with the help of a vertical pump through the pipeline to the pile
point for the purpose of boring.</p>
          </div>
          <div className="bentonite-4-3-right">

            <img src={bentonite_mixing} alt="" />
          </div>
        </div>

        <div className="bentonite-4-4">
          <h2>Bentonite recycling:</h2>

          <div className="bentonite-4-4-left">
            <h3>With Desander:</h3>
            <p>The returned bentonite with excavated muck coming out from the pile during concerning will be transferred to the Return tank
through a desander. After completion of every pile construction, the slurry from the returned tank will be checked for density,
Marsh viscosity, Sand content and pH value, and if found not satisfying the requirement, it will be corrected by adding fresh
bentonite slurry or otherwise, it will be disposed of. The tank will be properly cleaned and refilled with fresh bentonite slurry for
the next pile.</p>
          </div>

          <div className="bentonite-4-4-right">
            <h3>Without Desander:</h3>
            <p>The returned bentonite with excavated muck will join de-silting tank through an open channel. The feeding tank will be
positioned such that the returned bentonite from piling location joins sedimentation and after sedimentation overflows in-to
feeding/recycling/pumping tank. The water pump will feed water for making bentonite slurry and transfer to
storage/feeding/recycling/pumping tanks. After the quality checking, it can be allowed for reuse.</p>
          </div>
        </div>

        

      </div>
      </div>
    </div>
  )
}

export default Bentonite