import React, { useEffect, useState } from 'react';
import "../styles/productnav.css";

const Navigation = () => {
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    // Update the current page based on the window location
    setCurrentPage(window.location.pathname);
  }, []);

  return (
    <div className="productnav">
      <ul>
        <li><a href="/bentonite" className={currentPage === '/bentonite' ? 'active' : ''}>WG Bentonite For Piling</a></li>
        <li><a href="/drillingpolymer" className={currentPage === '/drillingpolymer' ? 'active' : ''}>WG Drilling Polymer</a></li>
        <li><a href="/microsilica" className={currentPage === '/microsilica' ? 'active' : ''}>WG Micro Silica(Densified)</a></li>
        <li><a href="/hydratedlime" className={currentPage === '/hydratedlime' ? 'active' : ''}>Hydrated Lime</a></li>
      </ul>
    </div>
  );
}

export default Navigation;
