import React from 'react';
import wallGriplogo from "../assets/wallGriplogo.png";
import "../styles/Footer.css";
import licenses from "../assets/licenses.jpg";
const Footer = () => {
  return (
    <>
    <div className="footer">
      <div className="footer_logo">
        <img src={wallGriplogo} alt='WallGRIP logo' />
      </div>
      <div className="footer_licenses">
        <img src={licenses} alt="" />
      </div>
      <div className="footer_address">
        <h5>Address:</h5>
        <p>B-234, Sector-4, DSIIDC Industrial Area Bawana, Delhi-110039</p>
      </div>
    </div>
    <div className="copyright">
      <p>Copyright © 2023 WallGrip Polymer | All Rights Reserved.</p>
    </div>
    </>
  )
}

export default Footer