import "../styles/Projects.css";
import React from 'react'
import ahemdabad from "../assets/ahemdabad.png";
import delhi from "../assets/delhi.png";
import dfccil from "../assets/dfccil.png";
import dwarka from "../assets/dwarka.png";
import gazhiabad from "../assets/gazhiabad.png";
import highspeed from "../assets/highspeed.png";
import jaipur from "../assets/jaipur.png";
import { Helmet } from "react-helmet";



const Projects = () => {
  return (
    <div className="Projects">
      <Helmet>
          <title>WallGripIndia-Projects</title>
        </Helmet>
      <h1>Our projects</h1>
      <div className="all_projects">
        <div className="projectcard">
          <img src={ahemdabad} alt="not found" />
          <p>Ahemdabad Metro</p>
        </div>
        <div className="projectcard">
          <img src={jaipur} alt="not found" />
          <p>Jaipur Metro</p>
        </div>
        <div className="projectcard">
          <img src={delhi} alt="not found" />
          <p>Delhi Metro</p>
        </div>
        <div className="projectcard">
          <img src={dwarka} alt="not found" />
          <p>Dwarka Expressway</p>
        </div>
        <div className="projectcard">
          <img src={highspeed} alt="not found" />
          <p>High Spees Rail Corridor </p>
        </div>
        <div className="projectcard">
          <img src={gazhiabad} alt="not found" />
          <p>Ghaziabad Metro</p>
        </div>
        <div className="projectcard">
          <img src={dfccil} alt="not found" />
          <p>DFCCIL Project</p>
        </div>
        
      </div>
    </div>
  )
}

export default Projects