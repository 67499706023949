import React from 'react'
import "../styles/drillingpolymer.css";
import product_sectiion from "../assets/product_section.jpg";
import Productnav from './Productnav';
import { Helmet } from 'react-helmet';

const Drillingpolymer = () => {
  return (
    <div className="Drillingpolymerpage">
      <Helmet>
          <title>WallGripIndia-DrillingPolymer</title>
        </Helmet>
      <div className="drilling_polymer_head">
        <img src={product_sectiion} alt="about_background_image" />
        <div className="about_head_text">WallGrip Products</div>
      </div>

      <Productnav/>

      <div className="drilling">
        <div className="drilling-1">
          <p>WallGrip Drilling Polymer polymer fluids offer many advantages over their bentonite counterparts, including improved foundation performance, lower environmental impacts, smaller site footprint and also simpler preparation, mixing and final disposal as they are used at much lower concentrations. They are also more easily managed than bentonite.</p>
          <p>Manoj Kumar Kharakia Co being trusted name in supply of polymer for various prestigious project throughout the country. We have multiple grades of polymer for different soil condition and complex engineering strata.</p>
        </div>

        <h2>Why WallGrip?</h2>

        <div className="drilling-2">
          <div className="drilling-2-left">
            <h3>WallGrip</h3>
            <ul>
              <li>Can be mixed instantly in surface tanks or directly in the excavation.</li>
              <li>Precipitate the cuttings to the bottom of the excavation for easy clean-out.</li>
              <li>Works in both fresh and saltwater.</li>
              <li>Slurries may be recycled an unlimited number of times without losing quality.</li>
              <li>Sand content after 20 minutes is usually less than 1.0%, making concrete placement easy with no affect in strength.</li>
              <li>Degradable; friendly to the environment.</li>
              <li>Can be broken down with household bleach or Hydrogen Peroxide; may be discharged in storm drain or left on ground to evaporate (depending on local regulations)</li>
            </ul>
          </div>

          <div className="drilling-2-right">
            <h3>Bentonite Slurry</h3>
            <ul>
              <li>24 hours advanced mixing for full hydration and needs expensive agitator equipped batch tanks.</li>
              <li>Add volume and weight; weight increases with viscosity.</li>
              <li>Cuttings suspend in the slurry for a long period of time; requires settling tank and de-sander.</li>
              <li>Bentonite will not hydrate in saltwater; it separates and breaks down in saltwater.</li>
              <li>Slurry loses quality after 2-3 times of recycling.</li>
              <li>High sand content due to its suspension properties; therefore, the strength of the concrete can be affected.</li>
              <li>Creates a layer impervious to water that can prevent plant growth.</li>
              <li>Unwanted mud must be properly handled and taken to a landfill for disposal; this is a cost the contractors have to bear.</li>
            </ul>
          </div>
        </div>

        <h2>What is WallGrip and How Does It Work?</h2>

        <div className="drilling-3">
          <p>WallGrip is a highly concentrated synthetic polyacrylamide polymer. WallGrip slurries achieve their large molecular structures by the linking of simple repeating units called monomers.</p>
          <p>WallGrip polymer slurries are anionic in nature; this means they have a negative ionic charge. Most soil particles have positive charges on their outside corners. Within the column of slurry, the negatively charged polymer molecules encapsulate and bridge between positively charged soil particles. The clustered soil particles are larger and quickly settle to the bottom of the excavation.</p>
          <p>When mixed with water, the water molecules attach themselves to the polymer chain forcing the polymer structure to swell and stiffen. Viscosity increases due to the entanglement shear strength of the hydrated polymer molecules.</p>
        </div>

        <h2>Types of Polymers</h2>

        <div className="drilling-4">
          <div className="drilling-4-4">
            <h3>WallGrip Polymer</h3>
            <p>Its premium range polymer which is used for most of soil conditions.</p>
          </div>
          <div className="drilling-4-4">
            <h3>WallGrip Premium Polymer</h3>
            <p>Advance grade polymer used for complex geology where required viscosity is above 80 sec</p>
          </div>
          <div className="drilling-4-4">
            <h3>WallGrip PDP Gold Polymer</h3>
            <p>Advance grade polymer with very high viscosity effect for sandy and complex strata.</p>
          </div>
          <div className="drilling-4-4">
            <h3>WG Bond</h3>
            <p>Advance polymer for controlling fluid loss and stabilizing piles in sand strata.</p>
          </div>
          <div className="drilling-4-4">
            <h3>Advance WG DP</h3>
            <p>Advance Polymer for removing clogging of sticky clay in formation.</p>
          </div>
          <div className="drilling-4-4">
            <h3>Advance WG FW</h3>
            <p>Advance Polymer for controlling mud rheology and extraction screw.</p>
          </div>
          <div className="drilling-4-4">
            <h3>Pro WG MC2</h3>
            <p>Advance Polymer for Controlling and handling mud in transportation</p>
          </div>
          <div className="drilling-4-4">
            <h3>WG TMC</h3>
            <p>Premium polymer for mud treatment</p>
          </div>
          <div className="drilling-4-4">
            <h3>WG TMF</h3>
            <p>Premium polymer for mud treatment and cleaning agent</p>
          </div>
          <div className="drilling-4-4">
            <h3>WG GOLD L</h3>
            <p>Advance polymer for increasing instant viscosity of slurry.</p>
          </div>
          <div className="drilling-4-4">
            <h3>WG Bento Bost</h3>
            <p>Premium polymer for boosting bentonite characteristic.</p>
          </div>
        </div>

        <div className="drilling-5">
          <h2>Packaging</h2>
          <h4>Our product is available in following packing:</h4>
          <ul>
            <li>Powder -25 KG HDPE packing</li>
            <li>Liquid - 25 KG PAILS, 100KG, 220 KG HDPE DRUMS</li>
            <li>We can also arrange product in 1050 kg IBC packing.</li>
          </ul>
        </div>

      </div>


    </div>
  )
}

export default Drillingpolymer